<template>
  <div class="content">
    <div class="container-fluid" style="margin-top:20px">
      <div class="row">
        <div class="col-lg-3 col-6">
          <!-- small box -->
          <div class="small-box bg-info">
            <div class="inner">
              <h3>{{ statistics.clients }}</h3>
              <p>{{ $t("HomeModule.clients") }}</p>
            </div>
          </div>
        </div>
        <!-- ./col -->
          <!-- ./col -->
          <div class="col-lg-3 col-6">
          <!-- small box -->
          <div class="small-box bg-custom-three">
            <div class="inner">
              <h3>{{ statistics.suppliers }}</h3>
              <p>{{ $t("HomeModule.suppliers") }}</p>
            </div>
          </div>
        </div>
        <!-- ./col -->
        <div class="col-lg-3 col-6">
          <!-- small box -->
          <div class="small-box bg-success">
            <div class="inner">
              <h3>{{ statistics.items }}</h3>
              <p>{{ $t("HomeModule.items") }}</p>
            </div>
          </div>
        </div>
        <!-- ./col -->
        <div class="col-lg-3 col-6">
          <!-- small box -->
          <div class="small-box bg-warning">
            <div class="inner">
              <h3>{{ statistics.sell }}</h3>
              <p>{{ $t("HomeModule.invoices_sell") }}</p>
            </div>
          </div>
        </div>
        <!-- ./col -->
        <div class="col-lg-3 col-6">
          <!-- small box -->
          <div class="small-box bg-custom-one">
            <div class="inner">
              <h3>{{ statistics.purchase }}</h3>
              <p>{{ $t("HomeModule.invoices_purchase") }}</p>
            </div>
          </div>
        </div>
        <!-- ./col -->
         <div class="col-lg-3 col-6">
          <!-- small box -->
          <div class="small-box bg-custom-two">
            <div class="inner">
              <h3>{{ statistics.return }}</h3>
              <p>{{ $t("HomeModule.invoices_return") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: function() {
    return {
      statistics: {
        clients: 0,
        suppliers: 0,
        items: 0,
        sell: 0,
        purchase: 0,
        return: 0,
      },
      load: true
    };
  },
  created() {
    this.getDashboard();
  },
  computed: {},
  watch: {},
  methods: {
    getDashboard() {
      this.axios
        .post("/dashboard")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.statistics = res.result.statistics;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("ads", res.message, 1);
          }
        })
        .catch(error => {
          this.handleError("dashboard", error);
        })
        .finally(() => {
          this.load = true;
        });
    }
  }
};
</script>
<style>
.small-box * {
  color: white;
}
.small-box {
  padding: 14px;
  border-radius: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.bg-custom-one {
  background-color: #60bf98 !important;
}
.bg-custom-two {
  background-color: #8c59d0 !important;
}
.bg-custom-three {
  background-color: #e48b4b !important;
}
.bg-custom-four {
  background-color: #67e6c9 !important;
}
.bg-custom-five {
  background-color: #e64b85 !important;
}
.bg-custom-six {
  background-color: #00b8ff !important;
}
.bg-custom-seven {
  background-color: #3fff5f !important;
}
.bg-custom-eight {
  background-color: #e64be1 !important;
}
</style>
