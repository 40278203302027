import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import ItemCategories from "../views/ItemCategories.vue";
import Items from "../views/Items.vue";
import Clients from "../views/Clients.vue";
import SellInvoices from "../views/SellInvoices.vue";
import PurchaseInvoices from "../views/PurchaseInvoices.vue";
import Permissions from "../views/Permissions.vue";
import Settings from "../views/Settings.vue";


import Login from "../views/Login.vue";

import auth from "../middleware/auth.js";
//import can from "../middleware/can.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { name: "Dashboard" },
    meta: { title: "home" }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Home,
    meta: { middleware: [auth], title: "DashboardModule" }
  },
  {
    path: "/item_categories",
    name: "ItemCategories",
    component: ItemCategories,
    meta: { middleware: [auth], title: "ItemCategoriesModule" }
  },
  {
    path: "/items",
    name: "Items",
    component: Items,
    meta: { middleware: [auth], title: "ItemsModule" }
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    meta: { middleware: [auth], title: "ClientsModule" }
  },
  {
    path: "/sellInvoices",
    name: "SellInvoices",
    component: SellInvoices,
    meta: { middleware: [auth], title: "SellInvoicesModule" }
  },
  {
    path: "/purchaseInvoices",
    name: "PurchaseInvoices",
    component: PurchaseInvoices,
    meta: { middleware: [auth], title: "PurchaseInvoicesModule" }
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: { middleware: [auth], title: "SettingsModule" }
  },
  {
    path: "/permissions",
    name: "Permissions",
    component: Permissions,
    meta: { middleware: [auth], title: "PermissionsModule",
    //permission: "admin.permissions.show"
   }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { title: "LoginModule" }
  },
  {
    path: "*",
    name: "Error",
    redirect: { name: "Login" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      to,
      store
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});
export default router;
