<template>
  <section class="content">
     <b-modal
      ref="add-client"
      id="add-client"
      :title="
        $t('SellInvoicesModule.add_client')"
      hide-footer
    >
      <div class="d-block">
        <form>
          <b-alert
            :variant="add_response_client.status ? 'success' : 'danger'"
            :show="add_response_client.show ? true : false"
            >{{ add_response_client.message }}</b-alert
          >
          <div class="row clearfix">
            <div class="col-10">
               <div class="form-group">
                          <label>{{ $t("ClientsModule.name") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('ClientsModule.name')
                            "
                            v-model="client_model.name"
                          />
                        </div>
                          <div class="form-group">
                          <label>{{ $t("ClientsModule.mobile") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('ClientsModule.mobile')
                            "
                            v-model="client_model.mobile"
                          />
                        </div>
            </div>
          </div>
          <button
            class="btn btn-primary"
            @click.prevent="AddClients"
            :disabled="disabled"
          >
            <span v-if="disabled">
              <i class="fa fa-spinner fa-spin" style="margin: 0 3px;"></i>
              {{ $t("loading") }}
              &nbsp; &nbsp; &nbsp;
              {{ add_progress }}%
            </span>
            <span v-else>{{ $t("save") }}</span>
          </button>
        </form>
      </div></b-modal
    >
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <b-tabs
        pills
        card
        no-key-nav
        content-class="custom-card"
        nav-wrapper-class="custom-nav-card"
      >
        <b-tab no-body :title="$t('all')" active v-if="$can('portal.invoices.show')">
          <template v-slot:title>
            <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
          </template>
          <b-card-text>
            <b-card no-body class="card">
              <b-card-header>
                <div class="card-tools">
                  <div class="input-group input-group-sm">
                    <input
                      type="text"
                      class="form-control float-right"
                      :placeholder="$t('search')"
                      v-model="search_key"
                    />
                    <div class="input-group-append">
                      <button
                        type="submit"
                        :disabled="search_disabled"
                        @click.prevent="searchFn"
                        class="btn btn-default"
                      >
                        <i
                          class="fa fa-spinner fa-spin"
                          v-if="search_disabled"
                        ></i>
                        <i class="fas fa-search" v-else></i>
                      </button>
                    </div>
                  </div>
                </div>
              </b-card-header>
              <!-- /.card-header -->
              <b-card-body class="table-responsive p-0">
                <div class="table-responsive">
                  <table class="table table-hover table-custom spacing8">
                    <thead>
                      <tr>
                        <th>{{ $t("SellInvoicesModule.invoice_no") }}</th>
                        <th>{{ $t("SellInvoicesModule.client") }}</th>
                        <th>{{ $t("SellInvoicesModule.total_amount") }}</th>
                        <th>{{ $t("SellInvoicesModule.created_at") }}</th>
                        <th class="w60">{{ $t("actions") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(invoice, i) in Invoices" :key="i">
                        <td v-text="invoice.id"></td>
                        <td v-text="(invoice.client)?invoice.client['name']:'-'"></td>
                        <td>
                          <span v-if="invoice.total_net">{{invoice.total_net}} {{$t("ItemsModule.sar") }}</span>
                          <span v-else>{{$t("ItemsModule.no_price") }}</span>
                        </td>
                        <td v-text="invoice.created_at"></td>
                        <td>
                          <button
                                 type="button"
                                 class="btn btn-sm btn-default m-1"
                                 v-tooltip.bottom="$t('eye')"
                                 @click.prevent="download_invoice(invoice)"
                                 v-if="$can('portal.invoices.show')"
                              >
                                <i class=" fa fa-eye"></i>
                              </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  
                </div>
              </b-card-body>
              <!-- /.card-body -->
              <b-card-footer class="card-footer clearfix">
                <b-pagination
                  class="m-2"
                  v-model="current_page"
                  :total-rows="pagination.total"
                  :per-page="pagination.per_page"
                  :prev-text="$t('previous')"
                  :next-text="$t('next')"
                  align="right"
                  :first-number="true"
                  :last-number="true"
                ></b-pagination>
              </b-card-footer>
            </b-card> </b-card-text
        ></b-tab>
        <b-tab no-body :title="$t('SellInvoicesModule.create')" v-if="$can('portal.invoices.add')">
          <template v-slot:title>
            <i class="fa fa-list ml-2 mr-2"></i> {{ $t('SellInvoicesModule.create') }}
          </template>
          <b-card-text>
            <b-card no-body class="card">
              <b-card-body>
                <div class="body">
                  <form>
                    <b-alert
                      :variant="add_response.status ? 'success' : 'danger'"
                      :show="add_response.show ? true : false"
                      >{{ add_response.message }}</b-alert
                    >
                    <div class="row clearfix">
                        <div class="col-12">
                          <div class="form-row">
                            <div class="col-md-6">
                              <label>{{ $t("SellInvoicesModule.client") }}</label>
                              <select class="form-control pt-0 col-md-12" v-model="invoice_model.client_id">
                                <option value="">{{ $t("select") }}</option>
                                <option
                                  :value="client.id"
                                  v-for="(client, i) in Clients"
                                  :key="i"
                                  >{{ client["name"] }}</option
                                >
                              </select>
                            </div>
                            <div class="col-md-3" style="display: flex;align-items: end;">
                              <button
                                v-if="$can('portal.clients.add')"
                                class="btn btn-primary"
                                @click.prevent="addClient"
                              >
                                {{ $t("SellInvoicesModule.add_client") }}
                              </button>
                         </div>
                            <div class="col-md-3" style="display: flex;align-items: end;    justify-content: end;">
                              <button
                                class="btn btn-primary"
                                @click.prevent="refreshItems"
                              >
                              تحديث المنتجات
                              </button>
                         </div>
                          </div>
                          <div class="form-row">
                            <div class="col-md-6">
                              <label>{{ $t("SellInvoicesModule.document_type") }}</label>
                              <select class="form-control pt-0 col-md-12" v-model="invoice_model.document_type">
                                <option value="" disabled>{{ $t("select") }}</option>
                                <option
                                  :value="type"
                                  v-for="(type, i) in document_types"
                                  :key="i"
                                  >{{ $t('SellInvoicesModule.document_types.'+type) }}</option
                                >
                              </select>
                            </div>
                          </div>
                      <div class="row clearfix">
    <div class="col-md-12">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th class="text-center"> # </th>
            <th class="text-center"> {{$t('SellInvoicesModule.product')}} </th>
            <th class="text-center"> {{$t('SellInvoicesModule.qty')}} </th>
            <th class="text-center"> {{$t('SellInvoicesModule.purchase_price')}} </th>
            <th class="text-center"> {{$t('SellInvoicesModule.sell_price')}} </th>
            <th class="text-center"> {{$t('SellInvoicesModule.discount')}} </th>
            <th class="text-center"> {{$t('SellInvoicesModule.total')}} </th>
          </tr>
        </thead>
        <tbody>
          <tr  v-for="(item, i) in invoice_model.details" :key="i">
            <td>{{i+1}}</td>
            <td style="width: 400px;">
                <v-select
                    :filter="filterItem"
                    :options="Items_search"
                    :clearable="false"
                    v-model="item.item_id"
                    :reduce="singleItem => singleItem.id"
                    :get-option-label="(option) => option['name_'+$i18n.locale] + ' ( رقم ' + option['id'] + ' )'"
                    :placeholder="$t('SellInvoicesModule.forms.choose_product')"
                  >        
                  </v-select>
            </td>
            <td><input type="number" v-model="item.qty" :placeholder="$t('SellInvoicesModule.forms.enter_qty')" oninput="this.value=(parseInt(this.value)||'')" class="form-control qty" step="1" min="0"/></td>
            <td><input type="number" v-model="item.purchase_price"  disabled class="form-control price" min="0"/></td>
            <td><input type="number" v-model="item.sell_price"  disabled class="form-control price" min="0"/></td>
            <td><input type="number" v-model="item.discount" class="form-control price" min="0"/></td>
            <td><input type="number" v-model="item.total"  disabled class="form-control price" min="0"/></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row clearfix">
    <div class="col-md-12">
      <button id="add_row" style="margin:0 2px" class="btn btn-default pull-left" @click.prevent="add_new_row">{{$t('add_row')}}</button>
      <button id='delete_row' style="margin:0 2px" class="pull-right btn btn-default" v-if="invoice_model.details.length != 0" @click.prevent="remove_row">{{$t('delete_row')}}</button>
    </div>
  </div>
  <div class="row clearfix" style="margin-top:20px">
    <div class="pull-right col-md-4">
      <table class="table table-bordered table-hover" id="tab_logic_total">
        <tbody>
          <tr>
            <th class="text-center">{{$t('SellInvoicesModule.total')}}</th>
            <td class="text-center"><input type="number" name='total_amount' id="total_amount" placeholder='0.00' class="form-control" v-model="total" readonly/></td>
          </tr>
          <tr>
            <th class="text-center">{{$t('SellInvoicesModule.tax_total')}}</th>
            <td class="text-center"><input type="number" name='tax_total' id="tax_total" placeholder='0.00' class="form-control" v-model="tax_total" readonly/></td>
          </tr>
          <tr>
            <th class="text-center">{{$t('SellInvoicesModule.total_net')}}</th>
            <td class="text-center"><input type="number" name='total_net' id="total_net" placeholder='0.00' class="form-control" v-model="total_net" readonly/></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
                        </div>
                    </div>

                    <button
                      class="btn btn-primary"
                      @click.prevent="addInvoice"
                      :disabled="disabled"
                    >
                      <span v-if="disabled">
                        <i
                          class="fa fa-spinner fa-spin"
                          style="margin: 0 3px;"
                        ></i>
                        {{ $t("loading") }}
                        &nbsp; &nbsp; &nbsp;
                        {{ add_progress }}%
                      </span>
                      <span v-else>{{ $t("add_invoice") }}</span>
                    </button>
                  </form>
                </div>
              </b-card-body>
              <!-- /.card-body -->
            </b-card> </b-card-text
        ></b-tab>
      </b-tabs>
        </div>
      </div>
    </div>
   </section>
</template>
<script>
import "vue-select/src/scss/vue-select.scss";
import vSelect from "vue-select";
// import Datepicker from 'vuejs-datepicker';
export default {
  name: "ItemsModule",
  components: {
      vSelect
    },
  data: function() {
    return {
      load: false,
      search_disabled: false,
      Invoices: [],
      disabled_s: [],
      pagination: {total : 0 ,per_page : 0},
      current_page : 1 ,
      client_model: { id: 0, name: "",mobile : ""},
      invoice_model: { id: 0,client_id : "" , type : "sell", details: [
        {
          item_id: '',
          name_en: '',
          qty: '',
          purchase_price: 0,
          sell_price: 0,
          discount : 0,
          total : 0
        }
      ],
      document_type: '',
      },
      document_types : [
            'simplified',
            'standard',
      ],
      total: 0,
      tax_total: 0,
      total_net: 0,
      add_response_client: { show: false, status: false, message: "" },
      add_response: { show: false, status: false, message: "" },
      edit_response: { show: false, status: false, message: "" },
      disabled: false,
      add_progress: 0,
      edit_progress: 0,
      search_key: "",
      filters: {},
      Clients: [],
      Items: [],
      Items_search: [],
    };
  },
  created() {
    this.filters["page"] = 1;
    this.filters["type"] = 'sell';
    this.getClients();
    this.getItems();
    this.getInvoices(this.filters);
  },
   watch:{
    current_page: {
      handler(){
      this.filters["page"] = this.current_page;
      this.filters["type"] = 'sell';
      this.getInvoices(this.filters);
     },
     deep: false
    },
    'invoice_model.details': {
      handler(){
          this.invoice_model.details.forEach(item => {
              let get_item = this.Items.find((i)=>{return i.id == item.item_id});
                  if(get_item){
                      item.sell_price = get_item.sell_price;
                      item.purchase_price = get_item.purchase_price;
                  }else{
                    item.sell_price = 0;
                    item.purchase_price = 0;
                }
          });
          let total = this.invoice_model.details.reduce(function(sum,item) {
                var total_row = ((item.sell_price != "" && item.qty != "") ? ((item.sell_price * item.qty) - item.discount) : 0);
                var total = sum + total_row;
                item.total = total_row;
                return total;
          },0);
          let total_net = total;
          let tax_total = total * .15;
          this.total = parseFloat(total).toFixed(2);
          this.tax_total = parseFloat(tax_total).toFixed(2);
          this.total_net = parseFloat(total_net + tax_total).toFixed(2);
     },
     deep: true
    }
  },
  methods: {
    refreshItems(){
      this.getItems();
    },
    filterItem(options, search){
      console.log(search);
       return options.filter(item => {
        return item['name_'+this.$i18n.locale].toLowerCase().indexOf(search) > -1 ||item.id == search;
      });
    },
    addClient(){
      this.$refs["add-client"].show();
    },
    AddClients(){
         this.add_response_client = { show: false, status: false, message: "" };
      this.disabled = true;
      this.axios
        .post("/clients/store", this.client_model, {
          onUploadProgress: progressEvent => {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.add_progress = percentCompleted;
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.client_model = {
              id: 0,
              name: "",
              mobile: ""
            };
            this.add_response_client = {
              show: true,
              status: true,
              message: res.message
            };
            this.Clients.unshift(res.result.object);
          } else {
            this.handleError("client_add", res.message, 1);
          }
        })
        .catch(error => {
          let res = error.response.data;
          if (res.error_flag == 1) {
            this.add_response_client = {
              show: true,
              status: false,
              message: res.message
            };
          } else {
            this.handleError("client_add", error);
          }
        })
        .finally(() => {
          this.add_progress = 0;
          this.disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    customFormatter(date){
      return this.$moment(date).format('MMMM  YYYY');
    },
    download_invoice(invoice){
            this.axios
        .get("/invoices/generate/"+invoice.id,{responseType: 'blob'})
        .then(response => {
            let res = response.data;
            const url = window.URL.createObjectURL(new Blob([res],{ type: 'application/pdf;base64' }));
            var newWin = window.open(url);
            newWin.focus();
            newWin.reload();
        })
        .catch(error => {
          if (error) {
            this.handleError("download_invoice", error);
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
    remove_row(){
      if(this.invoice_model.details.length != 0){
          this.invoice_model.details.splice(this.invoice_model.details.length-1, 1);
      }
    },
    add_new_row(){
      this.invoice_model.details.push({
          item_id: '',
          name_en: '',
          qty: '',
          purchase_price: 0,
          sell_price: 0,
          discount: 0,
          total : 0
        });
    },
     getItems(){
      this.axios
        .post("/items")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.Items = res.result;
            this.Items_search = res.result;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("getClients", res.message, 1);
          }
        })
        .catch(error => {
          this.handleError("getClients", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    getClients(){
      this.axios
        .post("/clients")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.Clients = res.result;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("getClients", res.message, 1);
          }
        })
        .catch(error => {
          this.handleError("getClients", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    editItems() {
      this.edit_response = { show: false, status: false, message: "" };
      this.disabled = true;
      this.axios
        .patch(
          "/items/" + this.item_edit_model.id,
          this.item_edit_model,
          {
            onUploadProgress: progressEvent => {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              this.edit_progress = percentCompleted;
            }
          }
        )
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            let index = this.Items.findIndex(
              obj => obj.id == this.item_edit_model.id
            );
            this.Items[index] = res.result.object;
            this.edit_response = {
              show: true,
              status: true,
              message: res.message
            };
          } else {
            this.handleError("item_edit", res.message, 1);
          }
        })
        .catch(error => {
          let res = error.response.data;
          if (res.error_flag == 1) {
            this.edit_response = {
              show: true,
              status: false,
              message: res.message
            };
          } else {
            this.handleError("item_edit", error);
          }
        })
        .finally(() => {
          this.edit_progress = 0;
          this.disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    searchFn() {
      this.search_disabled = true;
      this.filters["search_key"] = this.search_key;
      this.filters["type"] = 'sell';
      this.getInvoices(this.filters);
    },
    getInvoices(filters) {
      this.axios
        .post("/invoices", filters)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.Invoices = res.result.data;
            this.pagination = res.result.pagination;
          }else {
            this.handleError("getInvoices", res.message, 1);
          }
        })
        .catch(error => {
          if (error) {
            this.handleError("getInvoices", error);
          }
        })
        .finally(() => {
          this.search_disabled = false;
          this.load = true;
        });
    },
    addInvoice(){
            this.add_response = { show: false, status: false, message: "" };
      this.disabled = true;
      this.axios
        .post("/invoices/store", this.invoice_model, {
          onUploadProgress: progressEvent => {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.add_progress = percentCompleted;
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.invoice_model = { id: 0,client_id : "" , type : "sell", details: [
                    {
                      item_id: '',
                      name_en: '',
                      qty: '',
                      purchase_price: 0,
                      sell_price: 0,
                      discount : 0,
                      total : 0
                    }
                  ],
                  document_type: '',
                  };
            this.add_response = {
              show: true,
              status: true,
              message: res.message
            };
            this.Invoices.unshift(res.result.object);
            // setTimeout(()=>{
            //       this.download_invoice(res.result.object);
            // },1000);
          } else {
            this.handleError("invoice_add", res.message, 1);
          }
        })
        .catch(error => {
          let res = error.response.data;
          if (res.error_flag == 1) {
            this.add_response = {
              show: true,
              status: false,
              message: res.message
            };
          } else {
            this.handleError("invoice_add", error);
          }
        })
        .finally(() => {
          this.add_progress = 0;
          this.disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    }
  }
};
</script>
<!-- <style src="vue-multiselect/dist/vue-multiselect.min.css"></style> -->
<style scoped>
.multiselect__tags {
  border: 1px solid #e2e2e2 !important;
  font-size: 14px !important;
  font-weight: 100 !important;
  min-height: 38px !important;
  padding: 0 10px !important;
}
.multiselect__option {
  font-size: 14px !important;
}
.multiselect__tag {
  margin-top: 5px !important;
  margin-bottom: 0 !important;
}
.multiselect__tag span {
  color: white !important;
}
.multiselect__placeholder {
  font-size: 14px !important;
  color: #495057;
  margin-bottom: 0 !important;
  padding-top: 7px;
}
.badge {
  margin-left: 3px !important;
}
.order-table {
  color: #77797c;
}
.order-table td {
  padding: 3px;
}
.order-table td:first-child {
  font-weight: bold;
}
.order-table td:nth-child(2) {
  text-align: center;
}
.order-table tr {
  border-bottom: 1px solid #eee;
}
.products-title {
  margin: 0 auto;
  margin-top: 15px;
  padding: 5px 25px;
  background: #17c2d7;
  font-weight: bold;
  border-radius: 5px;
  color: white;
  width: max-content;
}
</style>
