<template>
  <div>
    <div class="login-box">
      <!-- /.login-logo -->
      <div class="card">
        <div class="card card-outline card-primary">
          <div class="card-header text-center">
            <h6>اسم المؤسسة</h6>
            <!-- <span style="display:block;color:red">غير مفعلة</span> -->
            <img src="logo.png" style="width:120px" />
          </div>

          <div class="card-body">
            <p class="login-box-msg">{{ $t("login_desc") }}</p>
            <form>
              <b-alert
                      :variant="login_response.status ? 'success' : 'danger'"
                      :show="login_response.show ? true : false"
                      ><i class="icon fas fa-exclamation-triangle"></i>
                      {{ login_response.message }}</b-alert
                    >
              <div class="input-group mb-3">
                <input
                  type="email"
                  class="form-control ltr"
                  placeholder="Email or Username"
                  v-model="login_data.user"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div class="input-group mb-3">
                <input
                  type="password"
                  class="form-control ltr"
                  placeholder="Password"
                  v-model="login_data.password"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- /.col -->
                <div class="col-5 mx-auto">
                  <button
                    type="submit"
                    :disabled="disabled"
                    class="btn btn-primary btn-block btn-flat"
                    @click.prevent="login"
                  >
                    {{ $t("login") }}
                    <b-spinner small v-if="disabled"></b-spinner>
                  </button>
                </div>
                <!-- /.col -->
              </div>
            </form>
          </div>
        </div>
        <!-- /.login-card-body -->
      </div>
    </div>
    <!-- /.login-box -->
  </div>
</template>

<script>
export default {
  name: "APP",
  data() {
    return {
      login_data: {},
      disabled: false,
      login_response: { show: false, status: false, message: "" }
    };
  },
  created() {
    document.body.classList.add("hold-transition", "login-page");

    if (this.$route.query.e) {
      this.$set(this.login_data, "email", this.$route.query.e);
    }
  },
  mounted() {},
  methods: {
    login() {
      this.disabled = true;
      this.axios
        .post("login", this.login_data)
        .then(response => {
          let res = response.data;
          console.log(res);
          if (res.error_flag == 0) {
            this.login_response = {
              show: true,
              status: true,
              message: res.message
            };
            this.$store.commit("setAccessToken", res.result.access_token);
            this.$store.commit("setPermissions", res.result.profile.permissions);
            let profile = res.result.profile;
            profile.image_url = res.result.profile.icon;
            this.$store.commit("setUser", profile);
            setTimeout(()=>{
              this.$router.push({ path: "/" });
            },2000);
          }
        })
        .catch(error => {
          console.log(error);
          let res = error.response.data;
          if (res.error_flag == 1) {
            this.login_response = {
              show: true,
              status: false,
              message: res.message
            };
          }
        })
        .finally(() => {
          this.disabled = false;
        });
    }
  }
};
</script>
<style>
.main-footer{
  margin-left: 0px !important;
  margin-right: 0px !important;
}
</style>
