import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    HomeModule: {
      clients: "Clients",
      items: "Items",
      invoices: "Invoices",
      vat_total: "Total Vat",
      total: "Total",
      net_total: "Net Total"
    },
    LoginModule: {
      title: "Login",
    },
    DashboardModule: {
      title: "Dashboard",
    },
    UsersTypesModule: {
      title: "Users Types",
      name: "Name",
      id: "ID"
    },
    BranchesModule: {
      title: "Branches",
      name: "Name",
      printer: "Printer",
      id: "ID"
    },
    PlacesModule: {
      title: "Places",
      name: "Name",
      branch: "Branch",
      id: "ID"
    },
    SeatsModule: {
      title: "Seats",
      name: "Name",
      place: "Place",
      id: "ID"
    },
    TotalTemplatesModule: {
      title: "Total Templates",
      name: "Name",
      id: "ID",
      terms: {
        title: "Fees",
        name: "Name",
        percentage: "Percentage",
        select_type: "Choose Fee Type"
      }
    },
    BillTypesModule: {
      title: "Bills Types",
      name: "Name",
      id: "ID",
      icon: "Icon",
      vat: "Vat Template"
    },
    ItemCategoriesModule: {
      title: "Items Categories",
      name: "Name",
      id: "ID",
      icon: "Icon",
      bill_type: "Bill Type",
      printer: "Printer",
      tags: {
        name: "Name",
        title : "Tags"
      }
    },
    ItemsModule: {
      title: "Items",
      name: "Name",
      id: "ID",
      code: "Code No",
      purchase_price: "Purchase Price",
      sell_price: "Sell Price",
      qty: "Qty",
      category: "Category",
      no_price: "No Price",
      sar: "جنية"
    },
    ClientsModule: {
      title: "Clients",
      name: "Name",
      id: "ID",
      mobile: "Mobile",
      email: "Email",
      tax_number: "Tax Number"
    },
    UsersModule: {
      title: "Users",
      name: "Name",
      id: "ID",
      mobile: "Mobile",
      email: "Email",
      username: "Username",
      type: "User Type",
      role: "Permission",
      password: "Password"
    },
    SellInvoicesModule: {
      title: "SellInvoices",
      name: "Name",
      id: "ID",
      invoice_no: "Invoice No",
      created_at: "Created At",
      create: "Create Invoice",
      client: "Client",
      product: "Product",
      qty: "Quantity",
      purchase_price: "Purchase Price",
      sell_price: "Sell Price",
      total: "Total",
      vat: "Vat",
      total_amount: "Total Amount",
      add_client: "Add New Client",
      forms: {
        enter_qty: "Enter Quantity",
        total: "Total",
        purchase_price: "Purchase Price",
        sell_price: "Sell Price",
        choose_product: "Choose Product",
      }
    },
    InvoicesModule: {
      title: "Invoices",
      name: "Name",
      id: "ID",
      invoice_no: "Invoice No",
      created_at: "Created At",
      create: "Create Invoice",
      client: "Client",
      product: "Product",
      qty: "Quantity",
      unit_price: "Unit Price",
      total: "Total",
      vat: "Vat",
      total_amount: "Total Amount",
      add_client: "Add New Client",
      forms: {
        enter_qty: "Enter Quantity",
        total: "Total",
        unit_price: "Unit Price",
        choose_product: "Choose Product",
      }
    },
    VatReportModule: {
      title: "VAT Statement",
      id: "#",
      date: "Date",
      sales_total: "Total sales",
      vat_total: "Total value added tax",
      date_from: "From date",
      date_to: "To date",
    },
    PermissionsModule: {
      title: "Permissions",
      name: "Name",
      id: "ID",
      Section: "Section",
      View: "View",
      Add: "Add",
      Edit: "Edit",
      Delete: "Delete",
      sections: {
        users_types: "Users Types",
        users: "Users",
        branches: "Branches",
        places: "Places",
        seats: "Seats",
        total_templates: "Total Templates",
        bills_types: "Bills Types",
        items_categories: "Items Categories",
        items: "Items",
        clients: "Clients",
        invoices: "Invoices",
        permissions: "Permissions"
      }
    },
    all: "All",
    actions: "Actions",
    status: "Status",
    edit: "Edit",
    add: "Add",
    delete: "Delete",
    view: "View",
    ar: "عربي",
    en: "English",
    search: "Search ...",
    save: "Save",
    error: "Something is wrong.",
    are_you_sure: "Are you sure.",
    yes: "Yes",
    no: "No",
    choose_file: "Choose file",
    select: "Select",
    change_status: "Change Status",
    login: "Login",
    email_mobile: "Email Or Mobile .",
    password: "Password .",
    Next:"Next",
    Previous:"Previous",
    loading: "Loading",
    previous: "Previous",
    next: "Next",
    login_desc: "Sign in to the control panel",
    add_row: "Add Row",
    delete_row: "Delete Row",
    download: "Download",
    eye: "View",
    add_invoice: "Create Invoice"
  },
  ar: {
    HomeModule: {
      clients: "العملاء",
      suppliers: "الموردين",
      items: "الاصناف",
      invoices_sell: "المبيعات",
      invoices_purchase: "المشتريات",
      invoices_return: "المرتجعات",
    },
    LoginModule: {
      title: "تسجيل الدخول",
    },
    DashboardModule: {
      title: "الرئيسية",
    },
    UsersTypesModule: {
      title: "انواع المستخدمين",
      name: "الاسم",
      id: "الرقم التعريفي"
    },
    BranchesModule: {
      title: "الفروع",
      name: "الاسم",
      printer: "الطابعة",
      id: "الرقم التعريفي"
    },
    PlacesModule: {
      title: "الحارات",
      name: "الاسم",
      branch: "الفرع",
      id: "الرقم التعريفي"
    },
    SeatsModule: {
      title: "الجلسات",
      name: "الاسم",
      place: "الحارة",
      id: "الرقم التعريفي"
    },
    TotalTemplatesModule: {
      title: "قوالب الضرائب",
      name: "الاسم",
      id: "الرقم التعريفي",
      terms: {
        title: "الضرائب",
        name: "الاسم",
        percentage: "النسبة",
        select_type: "اختر نوع الضريبة"
      }
    },
    BillTypesModule: {
      title: "انواع الفواتير",
      name: "الاسم",
      id: "الرقم التعريفي",
      icon: "الشعار",
      vat: "قالب الضريبة"
    },
    ItemCategoriesModule: {
      title: "مجموعة الاصناف",
      name: "الاسم",
      id: "الرقم التعريفي",
      icon: "الشعار",
      bill_type: "نوع الفاتورة",
      printer: "الطابعة",
      tags: {
        name: "الاسم",
        title : "الاوسمة"
      }
    },
    ItemsModule: {
      title: "الاصناف",
      name: "الاسم",
      id: "الرقم التعريفي",
      code: "رقم الصنف",
      purchase_price: "سعر الشراء",
      sell_price: "سعر البيع",
      qty: "الكمية",
      category: "التصنيف",
      no_price: "لم يتم تحديد سعر",
      sar: "جنية",
      choose_category: "اختر التصنيف"
    },
    ClientsModule: {
      title: "العملاء",
      name: "الاسم",
      id: "الرقم التعريفي",
      mobile: "رقم الهاتف",
      email: "البريد الالكتروني",
      tax_number: "الرقم الضريبي",
      street_name: "اسم الشارع",
      building_number: "رقم المبني",
      plot_identification: "رقم القطعة",
      city_sub_division: "تقسيم المدينة الفرعية",
      city: "المدينة",
      postal_number: "الرقم البريدي",
      registration_name: "الاسم التجاري"
    },
    SuppliersModule: {
      title: "الموردين",
      name: "الاسم",
      id: "الرقم التعريفي",
      mobile: "رقم الهاتف",
      email: "البريد الالكتروني",
      tax_number: "الرقم الضريبي"
    },
    UsersModule: {
      title: "المستخدمين",
      name: "الاسم",
      id: "الرقم التعريفي",
      mobile: "رقم الهاتف",
      email: "البريد الالكتروني",
      username: "اسم المستخدم",
      type: "نوع المستخدم",
      role: "الصلاحية",
      password: "كلمة المرور"
    },
    SellInvoicesModule: {
      title: "فواتير المبيعات",
      name: "الاسم",
      id: "الرقم التعريفي",
      invoice_no: "رقم الفاتورة",
      created_at: "تاريخ الفاتورة",
      create: "انشاء فاتورة",
      client: "العميل",
      product: "المنتج",
      qty: "الكمية",
      purchase_price: "سعر الشراء",
      sell_price: "سعر البيع",
      discount: "الخصم",
      total: "الاجمالي",
      tax_total: "الضريبة",
      total_net: "اجمالي الفاتورة",
      add_client: "اضافة عميل جديد",
      document_type: "نوع الفاتورة",
      forms: {
        enter_qty: "الكمية",
        choose_product: "اختر الصنف",
        total: "الاجمالي",
        purchase_price: "سعر الشراء",
        sell_price: "سعر البيع"
      },
      document_types: {
        'simplified': "فاتورة مبسطة",
        'standard': "فاتورة عادية",
      }
    },
    PurchaseInvoicesModule: {
      title: "فواتير المشتريات",
      name: "الاسم",
      id: "الرقم التعريفي",
      invoice_no: "رقم الفاتورة",
      created_at: "تاريخ الفاتورة",
      create: "انشاء فاتورة",
      supplier: "المورد",
      product: "المنتج",
      qty: "الكمية",
      purchase_price: "سعر الشراء",
      sell_price: "سعر البيع",
      supplier_price: "سعر المورد",
      total: "الاجمالي",
      vat: "الضريبة",
      total_amount: "اجمالي الفاتورة",
      add_supplier: "اضافة مورد جديد",
      forms: {
        enter_qty: "الكمية",
        choose_product: "اختر الصنف",
        total: "الاجمالي",
        purchase_price: "سعر الشراء"
      }
    },
    VatReportModule: {
      title: "كشف ضريبة القيمة المضافة",
      id: "#",
      date: "التاريخ",
      sales_total: "اجمالي المبيعات",
      vat_total: "اجمالي ضريبة القيمة المضافة",
      date_from: "من تاريخ",
      date_to: "الي تاريخ",
    },
    PermissionsModule: {
      title: "الصلاحيات",
      name: "الاسم",
      id: "الرقم التعريفي",
      Section: "القسم",
      View: "عرض",
      Add: "اضافة",
      Edit: "تعديل",
      Delete: "حذف",
      sections: {
        users_types: "انواع المستخدمين",
        users: "المستخدمين",
        branches: "الفروع",
        places: "المناطق",
        seats: "الجلسات",
        total_templates: "قوالب الضريبة",
        bills_types: "انواع الفواتير",
        items_categories: "مجموعة الاصناف",
        items: "الاصناف",
        clients: "العملاء",
        invoices: "الفواتير",
        reports: "التقارير",
        permissions: "الصلاحيات"
      }
    },
    SettingsModule: {
      title: "الاعدادات",
      View: "عرض",
      Add: "اضافة",
      Edit: "تعديل",
      Delete: "حذف",
      tax_settings: "الاعدادت الضريبية",
      tax_number: "الرقم الضريبي",
      tax_name: "الاسم الضريبي",
      commercial_registration_number: "رقم السجل التجاري",
      street_name: "اسم الشارع",
      building_number: "رقم المبني",
      plot_identification: "رقم القطعة",
      city_sub_division: "تقسيم المدينة الفرعية",
      city: "المدينة",
      postal_number: "الرقم البريدي",
      egs_serial_number: "رقم الحل",
      businessCategory: "نوع النشاط",
      commonName: "الاسم الشائع للنشاط",
      organizationalUnitName: "اسم الوحدة التنظيمي",
      organizationName: "اسم الوحدة",
      countryName: "اسم الدولة",
      registeredAddress: "العنوان المسجل",
      is_phase_two: "المرحلة الثانية",
      is_production: "الانطلاق للمرحلة الثانية",
      otp: "رمز التحقق",
      emailAddress: "البريد الالكتروني",
      invoice_type: "نوع اصدار الفواتير",
      invoice_types: {
        '1000': "فاتورة ضريبية",
        '0100': "فاتورة مبسطة",
        '1100': "الاثنين معا",
      }
    },
    all: "الكل",
    actions: "الخيارات",
    status: "الحالة",
    edit: "تعديل",
    add: "اضافة",
    delete: "حذف",
    view: "عرض",
    ar: "عربي",
    en: "انجليزي",
    search: "بحث ...",
    save: "حفظ",
    error: "هناك حطأ ما .",
    are_you_sure: "هل انت متأكد .",
    yes: "نعم",
    no: "لا",
    choose_file: "اختر ملف",
    select: "اختر",
    change_status: "تغيير الحالة",
    login: "تسجيل الدخول",
    email_mobile: "البريد الالكتروني او رقم الهاتف .",
    password: " كلمة المرور",
    Next:"التالي",
    Previous:"السابق",
    loading: "تحميل",
    previous: "السابق",
    next: "التالي",
    login_desc: "قم بتسجيل الدخول إلى لوحة التحكم",
    add_row: "اضافة صف",
    delete_row: "حذف صف",
    download: "تحميل",
    eye: "استعراض",
    add_invoice: "انشاء الفاتورة",
    save_and_generate_key: "حفظ وانشاء ختم التوقيع"
  }
};

export let i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "en", // set fallback locale
  messages // set locale messages
});
