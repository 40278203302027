<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <b-tabs
        pills
        card
        no-key-nav
        content-class="custom-card"
        nav-wrapper-class="custom-nav-card"
      >
        <b-tab no-body :title="$t('SettingsModule.tax_settings')" v-if="$can('portal.items_categories.add')">
          <template v-slot:title>
            <i class="fa fa-list ml-2 mr-2"></i> {{ $t('SettingsModule.tax_settings') }}
          </template>
          <b-card-text>
            <b-card no-body class="card">
              <b-card-body>
                <div class="body">
                  <form>
                    <b-alert
                      :variant="add_response.status ? 'success' : 'danger'"
                      :show="add_response.show ? true : false"
                      >{{ add_response.message }}</b-alert
                    >
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.tax_number") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.tax_number')
                            "
                            v-model="tax_settings.tax_number"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.tax_name") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.tax_name')
                            "
                            v-model="tax_settings.tax_name"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.commercial_registration_number") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.commercial_registration_number')
                            "
                            v-model="tax_settings.commercial_registration_number"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.street_name") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.street_name')
                            "
                            v-model="tax_settings.street_name"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.building_number") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.building_number')
                            "
                            v-model="tax_settings.building_number"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.plot_identification") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.plot_identification')
                            "
                            v-model="tax_settings.plot_identification"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.city_sub_division") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.city_sub_division')
                            "
                            v-model="tax_settings.city_sub_division"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.city") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.city')
                            "
                            v-model="tax_settings.city"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.postal_number") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.postal_number')
                            "
                            v-model="tax_settings.postal_number"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.egs_serial_number") }}</label>
                          <input
                            type="text"
                            style="direction: ltr;"
                            class="form-control col-md-12"
                            placeholder="1-ABC|2-PQR|3-XYZ"
                            v-model="tax_settings.egs_serial_number"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.businessCategory") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.businessCategory')
                            "
                            v-model="tax_settings.businessCategory"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.commonName") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.commonName')
                            "
                            v-model="tax_settings.commonName"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.organizationalUnitName") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.organizationalUnitName')
                            "
                            v-model="tax_settings.organizationalUnitName"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.organizationName") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.organizationName')
                            "
                            v-model="tax_settings.organizationName"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.countryName") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.countryName')
                            "
                            v-model="tax_settings.countryName"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.registeredAddress") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.registeredAddress')
                            "
                            v-model="tax_settings.registeredAddress"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.emailAddress") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.emailAddress')
                            "
                            v-model="tax_settings.emailAddress"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.invoice_type") }}</label>
                          <select
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.invoice_type')
                            "
                            v-model="tax_settings.invoice_type"
                          >
                          <option value="" disabled>{{ $t('SettingsModule.invoice_type') }}</option>
                          <option v-for="(type, i) in invoice_types" :key="i" :value="type">{{ $t("SettingsModule.invoice_types."+type)}}</option>
                        </select>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.otp") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('SettingsModule.otp')
                            "
                            v-model="tax_settings.otp"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.is_phase_two") }}</label>
                          <input
                          type="checkbox"
                          class="form-control col-md-12"
                          style="height: 17px; width: 17px;"
                          v-model="tax_settings.is_phase_two"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>{{ $t("SettingsModule.is_production") }}</label>
                          <input
                          type="checkbox"
                          class="form-control col-md-12"
                          style="height: 17px; width: 17px;"
                          v-model="tax_settings.is_production"
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      class="btn btn-primary"
                      @click.prevent="saveTaxSettings"
                      :disabled="disabled_tax_settings"
                      style="margin: 0 5px;"
                    >
                      <span v-if="disabled_tax_settings">
                        <i
                          class="fa fa-spinner fa-spin"
                          style="margin: 0 3px;"
                        ></i>
                        {{ $t("loading") }}
                        &nbsp; &nbsp; &nbsp;
                        {{ add_progress_tax_settings }}%
                      </span>
                      <span v-else>{{ $t("save") }}</span>
                    </button>
                    <button
                      class="btn btn-success"
                      @click.prevent="saveTaxSettingsAndGenerate"
                      :disabled="disabled_tax_settings_and_generate"
                      style="margin: 0 5px;"
                    >
                      <span v-if="disabled_tax_settings_and_generate">
                        <i
                          class="fa fa-spinner fa-spin"
                          style="margin: 0 3px;"
                        ></i>
                        {{ $t("loading") }}
                        &nbsp; &nbsp; &nbsp;
                        {{ add_progress_tax_settings_and_generate }}%
                      </span>
                      <span v-else>{{ $t("save_and_generate_key") }}</span>
                    </button>
                  </form>
                </div>
              </b-card-body>
              <!-- /.card-body -->
            </b-card> </b-card-text
        ></b-tab>
      </b-tabs>
        </div>
      </div>
    </div>
   </section>
</template>
<script>
export default {
  name: "ItemCategoriesModule",
  data: function() {
    return {
      load: false,
      disabled_s: [],
      tax_settings: { 
        tax_number: '',
        tax_name: '',
        commercial_registration_number: '',
        street_name: '',
        building_number: '',
        plot_identification: '',
        city_sub_division: '',
        city: '',
        postal_number: '',
        egs_serial_number: '',
        businessCategory: '',
        commonName: '',
        organizationalUnitName: '',
        organizationName: '',
        countryName: '',
        registeredAddress: '',
        is_phase_two: false,
        is_production: false,
        otp: '',
        emailAddress: '',
        invoice_type: '',
    },
      add_response: { show: false, status: false, message: "" },
      disabled_tax_settings: false,
      disabled_tax_settings_and_generate: false,
      add_progress_tax_settings: 0,
      add_progress_tax_settings_and_generate: 0,
      invoice_types: [
        '1000',
        '0100',
        '1100',
      ]
    };
  },
  created() {
    this.getTaxSettings();
  },
  methods: {
    saveTaxSettings() {
      this.add_response = { show: false, status: false, message: "" };
      this.disabled_tax_settings = true;
      this.axios
        .post("/settings/tax_save", this.tax_settings, {
          onUploadProgress: progressEvent => {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.add_progress_tax_settings = percentCompleted;
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.add_response = {
              show: true,
              status: true,
              message: res.message
            };
          } else {
            this.handleError("tax_settings", res.message, 1);
          }
        })
        .catch(error => {
          let res = error.response.data;
          if (res.error_flag == 1) {
            this.add_response = {
              show: true,
              status: false,
              message: res.message
            };
          } else {
            this.handleError("tax_settings", error);
          }
        })
        .finally(() => {
          this.add_progress = 0;
          this.disabled_tax_settings = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    saveTaxSettingsAndGenerate() {
      this.add_response = { show: false, status: false, message: "" };
      this.disabled_tax_settings_and_generate = true;
      this.tax_settings.save_type = 'generate';
      this.axios
        .post("/settings/tax_save", this.tax_settings, {
          onUploadProgress: progressEvent => {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.add_progress_tax_settings_and_generate = percentCompleted;
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.add_response = {
              show: true,
              status: true,
              message: res.message
            };
          } else {
            this.handleError("tax_settings", res.message, 1);
          }
        })
        .catch(error => {
          let res = error.response.data;
          if (res.error_flag == 1) {
            this.add_response = {
              show: true,
              status: false,
              message: res.message
            };
          } else {
            this.handleError("tax_settings", error);
          }
        })
        .finally(() => {
          this.add_progress = 0;
          this.disabled_tax_settings_and_generate = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    getTaxSettings(){
      this.axios
        .get("/settings/tax")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.tax_settings = res.result.object;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("settings", res.message, 1);
          }
        })
        .catch(error => {
          this.handleError("settings", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
  }
};
</script>
