<template>
  <div id="app">
    <div
      class="load-content"
      v-if="!main_load"
      style="position:fixed;width:100%;height:100%;padding:17%;background:#f4f6f9"
    >
      <b-spinner variant="primary" type="grow"></b-spinner>
    </div>

    <div class="wrapper" v-if="$route.name != 'Login' && main_load && isLogin">
      <nav-bar></nav-bar>
      <side-bar></side-bar>
      <div class="content-wrapper">
        <router-view />
        <br />
        <br />
      </div>
      <div id="sidebar-overlay" @click="hideMenu"></div>
    </div>
    <div v-else-if="main_load">
      <router-view />
    </div>
    <div class="main-footer">
           <h5 class="text-center">جميع الحقوق محفوظة</h5>
        </div>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import NavBar from "@/components/NavBar";
export default {
  name: "APP",
  components: { SideBar, NavBar },
  data() {
    return {
      main_load: false
    };
  },
  created() {
    document.body.classList.add(
      "sidebar-mini",
      "sidebar-open",
      "layout-fixed",
      "layout-navbar-fixed"
    );
    if (localStorage.locale) {
      this.changeLang(localStorage.locale);
    }

    if (this.$store.state.user) {
      this.getProfile().then(() => {
        this.main_load = true;
      });
    } else {
      this.main_load = true;
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.meta.title) {
          document.title =
            this.$t(this.$route.meta.title + ".title") + " | " + this.$store.state.title_suffix;
        } else {
          document.title = " " + this.$store.state.title_suffix;
        }
      }
    },
    "$i18n.locale"() {
      if (this.$route.meta.title) {
        document.title =
          this.$t(this.$route.meta.title + ".title") +
          " | " +
          this.$store.state.title_suffix;
      } else {
        document.title = " " + this.$store.state.title_suffix;
      }
    }
  },
  methods: {
    hideMenu() {
      document.body.classList.remove("sidebar-open");
      document.body.classList.add("sidebar-collapse");
    }
  }
};
</script>

<style lang="sass">
@import "./assets/css/adminlte.min.css"
@import "./assets/css/icheck-bootstrap.min.css"
@import "~bootstrap-vue/dist/bootstrap-vue.css"
@import "~@fortawesome/fontawesome-free/css/all.css"
@import "./assets/css/animate.min.css"
@import "~normalize.css/normalize.css"
@import "./assets/css/custom.scss"
@import '~vuejs-noty/dist/vuejs-noty.css'
@import "~vue-multiselect/dist/vue-multiselect.min.css"
</style>
